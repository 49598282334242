import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HelpComponent } from './help.component';
import { HelpPageResolve } from './help-page-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				component: HelpComponent,
				resolve: {
					model: HelpPageResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		HelpPageResolve
	]
})
export class HelpRoutingModule {
}
