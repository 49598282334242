import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactUsFormService } from './contact-us-form.service';
import { DetailService } from './details.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

const DEFAULT_SELECT = '...please select';
@Component({
	selector: 'vcm-contact-us-form',
	template: require('./contact-us-form.component.html')
})

export class ContactUsFormComponent {
	public reasons: string[];
	public details: string[] = null;
	detailsLoading: boolean = false;

	selectedReason: string = null;
	selectedDetail: string = null;
	contactMessage: string = null;

	reasonDefault: string = DEFAULT_SELECT;
	detailsDefault: string = DEFAULT_SELECT;

	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private route: ActivatedRoute,
	            private contactUsService: ContactUsFormService,
	            private detailService: DetailService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {reasons: string[]}) => {
			this.reasons = data.reasons;
		});
	}

	updateDetails(reason: string): void {
		this.selectedDetail = null;
		if (this.selectedReason) {
			this.detailsDefault = null;
			this.detailsLoading = true;
			this.detailService.getDetails(reason)
				.then(details => {
					this.detailsLoading = false;
					this.details = details || [];
					this.selectedDetail = this.details[0] || '';
				});
		} else {
			this.selectedReason = null;
			this.detailsDefault = DEFAULT_SELECT;
			this.details = null;
		}
	}

	onSubmit() {
		this.mask.addLoading();
		this.contactUsService.submitRequest({
			ContactReason: this.selectedReason,
			ContactDetails: this.selectedDetail,
			Message: this.contactMessage
		}).then((resp) => {
			this.mask.removeLoading();
			this.feedbackSubmitted.emit(resp);
			window.scrollTo(0, 0);
		});
	}
}
