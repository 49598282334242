import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us.component';
import { ReasonResolve } from './contact-us-form/reason-resolve.service';
import { AuthGuard } from '../../core/auth/auth.guard';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				component: ContactUsComponent,
				canActivate: [AuthGuard],
				resolve: {
					reasons: ReasonResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		ReasonResolve
	]
})
export class ContactUsRoutingModule {
}