import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class DetailService {

	private reasonDetails: Map<string, string[]> = new Map<string, string[]>();

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getDetails(reason: string): Promise<string[]> {
		if (this.reasonDetails.has(reason)) {
			return Promise.resolve(this.reasonDetails.get(reason));
		}
		return this.loadDetails(reason);
	}

	private loadDetails(reason: string) {
		return this.http.get(this.configService.baseUrl + '/api/ContactUs/?reason=' + reason)
			.toPromise()
			.then(res => {
				let details: string[] = res.json().map(detail => {
					return detail.ContactDetails;
				});
				this.reasonDetails.set(reason, details);
				return details;
			}).catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}