import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ReasonService {

	private reasons: string[];

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getReasons(): Promise<string[]> {
		if (this.reasons) {
			return Promise.resolve(this.reasons);
		} else {
			return this.loadReasons();
		}
	}

	private loadReasons(): Promise<string[]> {
		return this.http.get(this.configService.baseUrl + '/api/ContactUs')
			.toPromise()
			.then(res => {
				this.reasons = res.json().map(reason => {
					return reason.ContactReason;
				});
				return this.reasons;
			}).catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}