import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ReasonService } from './reason.service';

@Injectable()
export class ReasonResolve implements Resolve<string[]> {
	constructor(private service: ReasonService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<string[]> {
		return this.service.getReasons();
	}
}
