import { NgModule } from '@angular/core';
import { LegalComponent } from './legal.component';
import { LegalRoutingModule } from './legal-routing.module';
import { LegalPageService } from './legal-page.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		LegalRoutingModule
	],
	declarations: [
		LegalComponent
	],
	providers: [
		LegalPageService
	]
})
export class LegalModule {
}