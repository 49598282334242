import { Injectable } from '@angular/core';
import { PrivacyPageModel } from './privacy-page.model';
import { ConfigService } from '../../core/config.service';
import { HeadersClient } from '../../core/http/headers-client';
import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()

export class PrivacyPageService {
	private url = this.configService.baseUrl + '/api/staticpage?landingType=PRIVACY';
	private privacyPageModel: PrivacyPageModel;

	constructor(private configService: ConfigService,
	            private headers: HeadersClient,
	            private http: Http,
	            private logger: LoggerService) {
	}

	getPrivacyPageModel(): Observable<PrivacyPageModel> {
		if (this.privacyPageModel) {
			return Observable.of(this.privacyPageModel);
		} else {
			return this.loadPrivacyPageModel();
		}
	}

	private loadPrivacyPageModel(): Observable<PrivacyPageModel> {
		let options: RequestOptionsArgs = this.headers.getRequestOptions();
		return this.http.get(this.url, options)
			.map((res: Response) => {
				this.privacyPageModel = res.json();
				return this.privacyPageModel;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}