import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TermsComponent } from './terms.component';
import { TermsPageResolve } from './terms-page-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				component: TermsComponent,
				resolve: {
					model: TermsPageResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		TermsPageResolve
	]
})
export class TermsRoutingModule {
}
