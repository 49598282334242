import { Component, OnInit, Injectable, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TermsPageModel } from './terms-page.model';

@Component({
    template: require('./terms.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class TermsComponent implements OnInit {
    termsModel: TermsPageModel;

    public constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.data.forEach((data: {model: TermsPageModel}) => {
            this.termsModel = data.model;
        });
    }
}
