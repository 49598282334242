import { NgModule } from '@angular/core';
import { ContactUsRoutingModule } from './contact-us-routing.module';
import { ContactUsComponent } from './contact-us.component';
import { SharedModule } from '../../shared/shared.module';
import { ReasonService } from './contact-us-form/reason.service';
import { DetailService } from './contact-us-form/details.service';
import { ContactUsFormService } from './contact-us-form/contact-us-form.service';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { BackLinkComponent } from '../../shared/back-link.component';

@NgModule({
	imports: [
		SharedModule,
		ContactUsRoutingModule
	],
	declarations: [
		ContactUsComponent,
		ContactUsFormComponent
	],
	providers: [
		ReasonService,
		DetailService,
		ContactUsFormService,
		BackLinkComponent
	]
})
export class ContactUsModule {
}