import { NgModule } from '@angular/core';
import { HelpComponent } from './help.component';
import { HelpRoutingModule } from './help-routing.module';
import { HelpPageService } from './help-page.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		HelpRoutingModule
	],
	declarations: [
		HelpComponent
	],
	providers: [
		HelpPageService
	]
})
export class HelpModule {
}