import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LegalComponent } from './legal.component';
import { LegalPageResolve } from './legal-page-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				component: LegalComponent,
				resolve: {
					model: LegalPageResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		LegalPageResolve
	]
})
export class LegalRoutingModule {
}
