import { Injectable } from '@angular/core';
import { HelpPageModel } from './help-page.model';
import { ConfigService } from '../../core/config.service';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient } from "../../core/http/http-client";
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()

export class HelpPageService {
	private url = this.configService.baseUrl + '/api/landingpage?landingType=HELP';
	private helpPageModel: HelpPageModel;

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getHelpPageModel(): Observable<HelpPageModel> {
		if (this.helpPageModel) {
			return Observable.of(this.helpPageModel);
		} else {
			return this.loadHelpPageModel();
		}
	}

	private loadHelpPageModel(): Observable<HelpPageModel> {
		return this.http.get(this.url)
			.map((res: Response) => {
				this.helpPageModel = res.json();
				return this.helpPageModel;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}