import { NgModule } from '@angular/core';
import { PrivacyComponent } from './privacy.component';
import { PrivacyRoutingModule } from './privacy-routing.module';
import { PrivacyPageService } from './privacy-page.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		PrivacyRoutingModule
	],
	declarations: [
		PrivacyComponent
	],
	providers: [
		PrivacyPageService
	]
})
export class PrivacyModule {
}