import { Component, OnInit, Injectable, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpPageModel } from './help-page.model';

@Component({
    template: require('./help.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class HelpComponent implements OnInit {
    helpModel: HelpPageModel;

    public constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.data.forEach((data: {model: HelpPageModel}) => {
            this.helpModel = data.model;
        });
    }
}
