import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy.component';
import { PrivacyPageResolve } from './privacy-page-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				component: PrivacyComponent,
				resolve: {
					model: PrivacyPageResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		PrivacyPageResolve
	]
})
export class PrivacyRoutingModule {
}
