import { NgModule } from '@angular/core';
import { TermsComponent } from './terms.component';
import { TermsRoutingModule } from './terms-routing.module';
import { TermsPageService } from './terms-page.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		TermsRoutingModule
	],
	declarations: [
		TermsComponent
	],
	providers: [
		TermsPageService
	]
})
export class TermsModule {
}