import { Injectable } from '@angular/core';
import { LegalPageModel } from './legal-page.model';
import { ConfigService } from '../../core/config.service';
import { HeadersClient } from '../../core/http/headers-client';
import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()

export class LegalPageService {
	private url = this.configService.baseUrl + '/api/staticpage?landingType=LEGAL';
	private legalPageModel: LegalPageModel;

	constructor(private configService: ConfigService,
	            private headers: HeadersClient,
	            private http: Http,
	            private logger: LoggerService) {
	}

	getLegalPageModel(): Observable<LegalPageModel> {
		if (this.legalPageModel) {
			return Observable.of(this.legalPageModel);
		} else {
			return this.loadLegalPageModel();
		}
	}

	private loadLegalPageModel(): Observable<LegalPageModel> {
		let options: RequestOptionsArgs = this.headers.getRequestOptions();
		return this.http.get(this.url, options)
			.map((res: Response) => {
				this.legalPageModel = res.json();
				return this.legalPageModel;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}