import { Component } from '@angular/core';

@Component({
	template: require('./contact-us.component.html')
})

export class ContactUsComponent {
	isFeedbackSubmitted: boolean = false;

	displayFeedbackSubmittedMessage(feedback): void {
		this.isFeedbackSubmitted = true;
	}
}
