import { LegalPageModel } from './legal-page.model';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { LegalPageService } from './legal-page.service';

@Injectable()
export class LegalPageResolve implements Resolve<LegalPageModel> {
	constructor(private service: LegalPageService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<LegalPageModel> {
		this.mask.addLoading();
		return this.service.getLegalPageModel()
			.map((model: LegalPageModel) => {
				this.mask.removeLoading();
				return model;
			});
	}
}