import { Component, OnInit, Injectable, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivacyPageModel } from './privacy-page.model';

@Component({
	template: require('./privacy.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class PrivacyComponent implements OnInit {
	privacyModel: PrivacyPageModel;

	public constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		this.route.data.forEach((data: {model: PrivacyPageModel}) => {
			this.privacyModel = data.model;
		});
	}
}
