import { PrivacyPageModel } from './privacy-page.model';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { PrivacyPageService } from './privacy-page.service';

@Injectable()
export class PrivacyPageResolve implements Resolve<PrivacyPageModel> {
	constructor(private service: PrivacyPageService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<PrivacyPageModel> {
		this.mask.addLoading();
		return this.service.getPrivacyPageModel()
			.map((model: PrivacyPageModel) => {
				this.mask.removeLoading();
				return model;
			});
	}
}